<template>
    <v-container>
        <div class="d-flex justify-end pb-4">
            <!-- <v-btn color="primary" class="ma-1">Generar Codigo</v-btn> -->
        </div>
        <v-card class="mb-4">
            <v-form class="px-4 pt-4">
                <v-row>
                    <!-- Cliente -->
                    <v-col cols="12">
                        <v-text-field v-model="form.cliente" label="Cliente" outlined required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Cristal -->
                    <v-col cols="12" sm="6">
                        <v-select v-model="form.cristal" :items="cristalVentana" label="Cristal" item-text="idCristal"
                            outlined @change="buscarCodigoFamilia"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-checkbox v-model="form.hasPersianas" @change="buscarCodigoFamilia"
                            label="Persianas"></v-checkbox>
                    </v-col>
                </v-row>

                <v-row>
                    <!-- Material -->
                    <v-col cols="6">
                        <v-radio-group v-model="form.material" row @change="buscarCodigoFamilia">
                            <v-radio v-for="material in materialVentana" :key="material.id" :label="material.idMaterial"
                                :value="material.idMaterial" item-text="idMaterial"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <!-- Tipo -->
                    <v-col cols="12" sm="6" v-if="conBalconera.includes(form.cristal)">
                        <v-select v-model="form.tipo" :items="tipoVentana" label="Tipo" item-text="idTipo" outlined
                            :disabled="!conBalconera.includes(form.cristal)" @change="buscarCodigoFamilia"></v-select>
                    </v-col>
                </v-row>

                <!-- Medidas -->
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select v-model="form.height" :items="form.opcionesAltura" label="Altura" outlined
                            @change="medidas"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select v-model="form.width" :items="form.opcionesAnchura" label="Anchura" outlined
                            @change="medidas"></v-select>
                    </v-col>
                </v-row>
                <!-- Datos -->
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.cantidad" label="Cantidad" outlined required
                            type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.codigo" label="Codigo" required outlined readonly></v-text-field>
                    </v-col>
                </v-row>
                <div class="d-flex justify-end">
                    <v-btn color="error" class="ma-2" @click="clearForm">Limpiar</v-btn>
                    <v-btn color="primary" class="ma-2" @click="addOrUpdateForm">{{ editIndex === -1 ? 'Añadir' :
                        'Actualizar' }}</v-btn>
                </div>
            </v-form>
        </v-card>

        <!-- Tabla-->
        <v-card class="mb-4">
            <table class="styled-table pa-4">
                <thead>
                    <tr>
                        <th class="th-space">Nombre</th>
                        <th class="th-space">Codigo</th>
                        <th class="th-space">Cantidad</th>
                        <th class="th-space">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(form, index) in allForms" :key="index">
                        <th>{{ form.tipo }} {{ form.cristal }} {{ form.material }} {{ form.hasPersianas == 1 ? 'con persiana' :
                        '' }} {{ form.width }} x {{ form.height }}</th>
                        <td>{{ form.codigo }}</td>
                        <td>{{ form.cantidad }}</td>
                        <td>
                            <v-btn color="primary" class="mr-2" small @click="editForm(index)">Editar</v-btn>
                            <v-btn color="error" small @click="deleteForm(index)">Eliminar</v-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-card>
        <v-row justify-end>
            <v-col cols="12" >
                <v-btn color="primary" class="mr-2" @click="generar">Generar</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "WindowConfigForm",
    data: () => ({
        form: {
            tipo: "Ventana",
            cristal: "1 hoja",
            material: "Blanco",
            hasPersianas: false,
            codigo_familia: "",
            cantidad: "",
            height: null,
            width: null,
            fichero_medidas: "",
            codigo: "",
            opcionesAltura: [],
            opcionesAnchura: [],
            cliente: "",
        },
        allForms: [],
        editIndex: -1,
        conBalconera: ["1 hoja", "2 hojas"],
        tipoVentana: [],
        cristalVentana: [],
        materialVentana: [],
        opcionesAltura: [],
        opcionesAnchura: [],
    }),
    methods: {
        async generar() {

        },
        async getTipos() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-tipo`,
            });
            this.tipoVentana = data;
        },
        async getCristal() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-cristal`,
            });
            this.cristalVentana = data;
        },
        async getMaterial() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-material`,
            });
            this.materialVentana = data;
        },
        addOrUpdateForm() {
            if (this.form.cantidad && this.form.codigo) {
                if (this.editIndex === -1) {
                    this.allForms.push({ ...this.form });
                } else {
                    this.$set(this.allForms, this.editIndex, { ...this.form });
                    this.editIndex = -1;
                }
                this.clearForm();
            }
            else {
                alert("Por favor, rellene todos los campos");
            }

        },
        clearForm() {
            this.form = {
                tipo: "Ventana",
                cristal: "1 hoja",
                material: "Blanco",
                hasPersianas: false,
                codigo_familia: "",
                cantidad: "",
                height: null,
                width: null,
                fichero_medidas: "",
                codigo: "",
                opcionesAltura: [],
                opcionesAnchura: [],
            };
        },
        deleteForm(index) {
            this.allForms.splice(index, 1);
        },
        editForm(index) {
            this.form = { ...this.allForms[index] };
            this.editIndex = index;
        },
        async medidas() {
            var alto = this.form.height;
            var ancho = this.form.width;

            if (alto && ancho) {
                const paddedAlto = alto.toString().padStart(3, '0');
                const paddedAncho = ancho.toString().padStart(3, '0');

                this.form.codigo = `${this.form.codigo_familia}${paddedAncho}${paddedAlto}`;
            }
        },
        async buscarCodigoFamilia() {
            try {
                if (this.conBalconera && !this.conBalconera.includes(this.form.cristal)) {
                    this.form.tipo = "Ventana";
                }
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/ventanas/search`, {
                    params: {
                        idTipo: this.form.tipo,
                        idCristal: this.form.cristal,
                        idMaterial: this.form.material,
                        persiana: this.form.hasPersianas,
                    },
                });

                const data = response.data[0] || { codigo_familia: '', ancho: [], alto: [] };

                this.form.codigo_familia = data.codigo || '';
                this.form.opcionesAnchura = data.ancho || [];
                this.form.opcionesAltura = data.alto || [];

            } catch (error) {
                console.error("Error searching ventanas:", error);
                this.form.codigo_familia = '';
                this.form.opcionesAnchura = [];
                this.form.opcionesAltura = [];
            }

            this.medidas();
        }
    },
    created() {
        this.getTipos();
        this.getCristal();
        this.getMaterial();
    },
};
</script>
<style scoped>
.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table thead tr {
    text-align: left;
    font-weight: bold;
}

.th-space {
    padding-right: 20px;
}
</style>
